<template>
	<VueSlickCarousel :class="[{'slick-one-slide': content.length<2}, 'reviewSlider', 'animationBlockFadeUp']" ref="slider" v-bind="slickSettings">
		<div class="reviewSlider__item" v-for="slide in content">
			<div class="reviewSlider__logo" v-html="slide.logo" v-if="slide.logo"></div>
			<div class="reviewSlider__logo" v-else-if="slide.logoImageSrc"><img :src="slide.logoImageSrc"></div>
			<div class="reviewSlider__text" v-html="slide.title"></div>
			<div class="reviewSlider__author" v-if="slide.authorName">
				<div class="reviewSlider__authorImg" v-lazy:background-image="slide.authorImageSrc" v-if="slide.authorImageSrc"></div>
				<div class="reviewSlider__authorContent">
					<p class="reviewSlider--name" v-if="slide.authorName" v-html="slide.authorName"></p>
					<p class="reviewSlider--desc" v-if="slide.authorDesc" v-html="slide.authorDesc"></p>
				</div>
			</div>
		</div>
		<template #customPaging="page">
			<div class="dot"></div>
		</template>
	</VueSlickCarousel>
</template>


<style lang="sass">
	@import '@/assets/new_sass/ReviewSlider'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['content'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				infinite: true
			}
		}),
		beforeMount(){
			this.content.forEach(function(item) {
				item.authorImageSrc = require(`@/assets/img/${item.authorImage}`);

				if(item.logoImage){
					item.logoImageSrc = require(`@/assets/img_new/${item.logoImage}`);
				}
			});
		},
		components: {
			VueSlickCarousel,
		}
	}
</script>